import React from "react"
import Layout from "../../components/layout"
import gameInit from '../../../static/nuzaq'

class Game extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    gameInit()
  }

  restartGame() {
    gameInit()
  }

  render() {
    return (
      <div>
      <h1>Snake Game</h1>
        <canvas id="myCanvas" width="480" height="360"></canvas>
        <button onClick={this.restartGame}>play again</button>
        <p>This is the classic game snake written in JavaScript, and implemented via the CANVAS tag.</p>
        <p>Its placed here via React / Gatsby.</p>
      </div>
    );
  }
}

export default ({ data }) => {
  return (
    <Layout>
      <Game/>
    </Layout>
  )
}
